<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">试卷详情</span>
        <!-- 面包屑 -->
        <div class="crumbs">
          <router-link class="crumbs_item" tag="a" to="/admin/Search/UserQuery">用户查询 / </router-link>
          <!-- <router-link class="crumbs_item" tag="a" to='/admin/Search/UserDetail?userId=' + this.id>模拟考试 / </router-link> -->
          <span class="crumbs_item" @click="$router.go(-1)">模拟考试 / </span>
          <span class="crumbs_item crumbs_last">试卷详情</span>
        </div>
      </div>
    </div>
    <!-- 答题列表 -->
    <div class="answer_box">
      <div class="title">
        <h3>{{data.paperName}}</h3>
        <h3>总得分：{{data.totalScore}}分</h3>
      </div>
      <div class="answer_list">
        <div class="answer_item" v-for="(item,index) in stemList" :key="index">
          <h4>{{index + 1}}. {{item.title}}？({{item.score}}分)</h4>
          <p v-for="(i,index) in item.optionList" :key="index">{{index==0?'A':index==1?'B':index==2?'C':index==3?'D':index==4?'E':index==5?'F':index==6?'G':'H'}}. {{i.optionName}}</p>
          <div class="answer">
            <div>
              <p>正确答案：{{item.answer}}</p>
              <p>学员答案：{{item.checked ? item.checked : '未选'}}</p>
            </div>
            <span class="correct" :class="{'error' : item.answer != item.checked}">{{ item.answer === item.checked?'正确':'错误'}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: { },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return { 
      data: [],
      stemList: [],
    }
  },
  // 事件处理器
  methods: {
    getList(id){
      this.$ajax({
        url: "/hxclass-management/exam/examinee/paper/detail",
        params: {
          userPaperId: id,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.data = res.data;
          this.stemList = this.data.stemList
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.id = this.$route.query.userpPaperId;
    this.getList(this.id);
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.answer_box{
  padding: 20px 0;
  .title{
    display: flex;
    justify-content: space-between;
    h3{
      font-size: 22px;
      font-weight: 500;
      color: #333333;
    }
  }
  .answer_list{
    margin-top: 40px;
    .answer_item{
      margin-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
      color: #333333;
      h4{
        font-size: 18px;
      }
      >p{
        margin: 20px 0;
      }
      .answer{
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        align-items: center;
        background: #f9f9f9;
        p{
          margin: 10px 0;
        }
      }
    }
    .correct{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #ffffff;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      background: @theme;
      transform:rotate(-45deg);
      -webkit-transform:rotate(-45deg);
      -moz-transform:rotate(-45deg);
    }
    .error{
      background: red;
    }
  }
}
</style>
